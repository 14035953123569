import {
  Button,
  PlusSolid,
  Select,
  Snackbar,
  TextField,
  Trans,
  Typography,
  useTranslation,
  useUrlServerPagination,
  WarningCircle,
  XSolid,
} from "@lumar/shared";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Icon,
  makeStyles,
  MenuItem,
  Tooltip,
} from "@material-ui/core";
import { GridApiRef } from "@mui/x-data-grid-pro";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";
import { useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { ReportRowsFilter } from "../../_common/connection-filtering/types";
import { Routes } from "../../_common/routing/routes";
import { useCreateCustomReportTemplateMutation } from "../../graphql";
import { ReportGridColumn } from "../../report/report-rows/report-grid/columns/ReportGridColumns.types";
import {
  getMetricsGroupingsFromGrid,
  mapTotalSignToInt,
  mapTotalWeightToFloat,
} from "../_common/CustomReportHelpers";
import { CreateCustomReportTemplateFormValues } from "../_common/CustomReportTypes";
import { createCustomReportTemplateSchema } from "../_common/CustomReportValidation";
import { countFilters } from "./filterValidation";

const useStyles = makeStyles((theme) => ({
  formLine: {
    marginBottom: theme.spacing(2),
  },
  formTwoColumns: {
    display: "flex",
    justifyContent: "space-between",
    "& > *": {
      width: "calc(50% - 4px)",
    },
  },
  dialog: {
    width: "100%",
  },
  dialogTitle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    padding: theme.spacing(2, 3),
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
  },
  dialogContent: {
    padding: theme.spacing(3),
  },
  alert: {
    display: "flex",
    alignItems: "center",
    backgroundColor: theme.palette.red["50"],
    padding: theme.spacing(2),
  },
  alertIcon: {
    color: theme.palette.red["500"],
    marginRight: theme.spacing(1),
  },
}));

interface CreateCustomReportDialogProps {
  accountId: string;
  crawlId: string;
  projectId: string;
  reportTemplateCode: string;
  reportTemplateQueryVersion: number;
  reportQueryVersion: number;
  lastFinishedCrawlId: string;
  cardMetrics: ReportGridColumn[];
  foundInSources: ReportGridColumn[];
  filter?: ReportRowsFilter;
  gridApiRef: GridApiRef;
  isLoading?: boolean;
  maxCustomReportsReached: boolean;
}

export const CreateCustomReportDialog = (
  props: CreateCustomReportDialogProps,
): JSX.Element => {
  const { t } = useTranslation("customReports");
  const { pageInfo } = useUrlServerPagination(0);
  const classes = useStyles();
  const [createOpen, setCreateOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const [createCustomReportMutation] = useCreateCustomReportTemplateMutation({
    refetchQueries: ["CrawlContextCustomReports"],
    onCompleted: () => {
      enqueueSnackbar(
        <Snackbar
          variant="success"
          title={
            <Trans
              ns="customReports"
              i18nKey="customReportTemplateCreated"
              components={{
                customReportsManagerLink: (
                  <Link
                    to={Routes.CustomReportTemplateManager.getUrl({
                      accountId: props.accountId,
                      projectId: props.projectId,
                      crawlId: props.crawlId,
                    })}
                  />
                ),
              }}
            />
          }
        />,
      );
      closeDialogAndReset();
    },
    onError: (error) => {
      enqueueSnackbar(<Snackbar variant="error" title={error.message} />);
    },
  });

  const initialValues: CreateCustomReportTemplateFormValues = {
    projectId: props.projectId,
    reportTemplateCode: props.reportTemplateCode,
    name: "",
    description: "",
    totalSign: "neutral",
    totalWeight: "medium",
    filter: props.filter ?? {},
    metricsGroupings: getMetricsGroupingsFromGrid({
      cardMetrics: props.cardMetrics,
      gridApiRef: props.gridApiRef,
    }),
    orderBy: pageInfo.orderBy ?? undefined,
  };

  const maxFiltersReached = useMemo(
    () => countFilters(props.filter) > 20,
    [props.filter],
  );

  const formik = useFormik<CreateCustomReportTemplateFormValues>({
    enableReinitialize: true,
    initialValues,
    validationSchema: createCustomReportTemplateSchema(t),
    onSubmit: async (values) => {
      await createCustomReportMutation({
        variables: {
          input: {
            projectId: values.projectId,
            reportTemplateCode: values.reportTemplateCode,
            name: values.name,
            description: values.description,
            totalSign: mapTotalSignToInt(values.totalSign),
            totalWeight: mapTotalWeightToFloat(values.totalWeight),
            filter: values.filter,
            metricsGroupings: getMetricsGroupingsFromGrid({
              cardMetrics: props.cardMetrics,
              gridApiRef: props.gridApiRef,
            }),
            orderBy: values.orderBy,
          },
        },
      });
    },
  });

  const closeDialogAndReset = (): void => {
    setCreateOpen(false);
    formik.resetForm();
    formik.validateForm(initialValues);
  };

  const areFiltersApplied = Boolean(props.filter);
  const isReportTemplateOutdated =
    props.reportQueryVersion !== props.reportTemplateQueryVersion;

  const isLatestCrawl = props.crawlId !== props.lastFinishedCrawlId;

  const isCreationDisabled =
    !areFiltersApplied ||
    isReportTemplateOutdated ||
    isLatestCrawl ||
    props.isLoading;

  const creationDisabledReasons: [boolean, string][] = [
    [isLatestCrawl, "You can only create custom reports from latest crawls"],
    [
      isReportTemplateOutdated,
      "This crawl is using outdated report which cannot be used as a base for custom report. Please run another crawl.",
    ],
    [
      !areFiltersApplied,
      "You need to apply a filter to create a custom report.",
    ],
  ];

  const disabledReason =
    creationDisabledReasons.find(([isCurrentReason]) => isCurrentReason)?.[1] ??
    "";

  return (
    <>
      <Tooltip title={disabledReason} placement="top">
        <div>
          <Button
            variant="contained"
            color="primary"
            size="large"
            startIcon={<PlusSolid />}
            onClick={() => setCreateOpen(true)}
            disabled={isCreationDisabled}
          >
            {t("createCustomReport")}
          </Button>
        </div>
      </Tooltip>
      <Dialog
        open={createOpen}
        onClose={() => closeDialogAndReset()}
        classes={{ paper: classes.dialog }}
      >
        <DialogTitle disableTypography className={classes.dialogTitle}>
          <Typography variant="h2">{t("createCustomReport")}</Typography>
        </DialogTitle>

        <DialogContent className={classes.dialogContent}>
          <form
            id="createCustomReportTemplateForm"
            onSubmit={formik.handleSubmit}
          >
            <div className={classes.formLine}>
              <TextField
                label="Report name*"
                id="name"
                name="name"
                value={formik.values.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
              />
            </div>
            <div className={classes.formLine}>
              <TextField
                label="Report description"
                multiline={true}
                minRows={4}
                maxRows={4}
                id="description"
                name="description"
                value={formik.values.description}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.description &&
                  Boolean(formik.errors.description)
                }
                helperText={
                  formik.touched.description && formik.errors.description
                }
              />
            </div>
            <div className={classes.formTwoColumns}>
              <Select
                label="Sign"
                id="totalSign"
                name="totalSign"
                value={formik.values.totalSign}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              >
                <MenuItem value="negative">
                  {t("templateSign.negative")}
                </MenuItem>
                <MenuItem value="neutral">{t("templateSign.neutral")}</MenuItem>
                <MenuItem value="positive">
                  {t("templateSign.positive")}
                </MenuItem>
              </Select>
              <Select
                label="Weight"
                id="totalWeight"
                name="totalWeight"
                value={formik.values.totalWeight}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              >
                <MenuItem value="none">{t("templateWeight.none")}</MenuItem>
                <MenuItem value="low">{t("templateWeight.low")}</MenuItem>
                <MenuItem value="medium">{t("templateWeight.medium")}</MenuItem>
                <MenuItem value="high">{t("templateWeight.high")}</MenuItem>
                <MenuItem value="critical">
                  {t("templateWeight.critical")}
                </MenuItem>
              </Select>
            </div>
          </form>
        </DialogContent>
        {maxFiltersReached ? (
          <div className={classes.alert}>
            <Icon className={classes.alertIcon}>
              <WarningCircle />
            </Icon>

            <Typography variant="body2">
              <Trans
                ns="customReports"
                i18nKey="maxFiltersReached"
                components={{
                  bold: <Typography variant="subtitle3Bold" component="span" />,
                }}
              />
            </Typography>
          </div>
        ) : null}
        {props.maxCustomReportsReached ? (
          <div className={classes.alert}>
            <Icon className={classes.alertIcon}>
              <WarningCircle />
            </Icon>
            <Typography variant="body2">
              <Trans
                ns="customReports"
                i18nKey="maxCustomReportsReached"
                components={{
                  bold: <Typography variant="subtitle3Bold" component="span" />,
                }}
              />
            </Typography>
          </div>
        ) : null}
        <DialogActions>
          <Button
            disabled={formik.isSubmitting}
            variant="outlined"
            onClick={() => closeDialogAndReset()}
            startIcon={<XSolid />}
          >
            {t("cancel")}
          </Button>
          <Button
            loading={formik.isSubmitting}
            disabled={
              !formik.isValid ||
              formik.isSubmitting ||
              isCreationDisabled ||
              maxFiltersReached
            }
            type="submit"
            form="createCustomReportTemplateForm"
            variant="contained"
            color="primary"
            startIcon={<PlusSolid />}
          >
            {t("createCustomReport")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
