import React from "react";
import {
  useTranslation,
  FlagBugReport,
  NestedNavigationMenuChildItemAdornmentProps,
} from "@lumar/shared";
import { useSearchParam } from "../../../_common/routing/useSearchParam";
import { Metrics, ResourceDetailData } from "../../data/types";
import { AccessibilityReport } from "./sections/AccessibilityReport";
import { SvgIcon } from "@material-ui/core";
import { useAccessibilityReports } from "./useAccessibilityReports";
import { AccessibilitySummary } from "./sections/AccessibilitySummary";
import { AccessibilityAllMetrics } from "./sections/AccessibilityAllMetrics";
import { AccessibilityChangedMetrics } from "./sections/AccessibilityChangedMetrics";
import { ReportRowsFilter } from "../../../_common/connection-filtering/types";
import { ReportCount } from "../../ReportCount";
import { useAttachmentSections } from "../../attachments/useAttachmentSections";

type Section = {
  code: string;
  name: string;
  component: React.ElementType<{ data: ResourceDetailData }>;
};

export type ReportConfig = {
  code: string;
  name?: string;
  items: {
    code: string;
    name: string;
    count?: number;
    countMetric?: string;
    countMetricOffset?: number;
    filter: ReportFilter;
  }[];
};

export type ReportFilter = {
  reportTemplateCode: string;
  filter: ReportRowsFilter;
};

export type AccessibilityDetailsSections = {
  code: string;
  icon: typeof SvgIcon;
  name: string;
  component: React.ElementType<{ data: ResourceDetailData }>;
  main: Section[];
  reports: {
    code: string;
    name?: string;
    items: (Section & {
      adornment?: React.ComponentType<NestedNavigationMenuChildItemAdornmentProps>;
    })[];
  }[];
};

type Result = {
  section: AccessibilityDetailsSections;
  active: Section;
};

export function useAccessibilityDetailsSections(metrics: Metrics): Result {
  const { t } = useTranslation("resourceDetail");
  const type = useSearchParam("type");

  const reports = useAccessibilityReports(metrics);
  const attachmentSections = useAttachmentSections(metrics);

  const section = React.useMemo<AccessibilityDetailsSections>(
    () => ({
      code: "accessibility",
      name: t("accessibilitySummary"),
      icon: FlagBugReport,
      component: AccessibilitySummary,
      main: [
        {
          code: "a11yAllMetrics",
          name: t("accessibilityAllMetrics"),
          component: AccessibilityAllMetrics,
        },
        {
          code: "a11yChangedMetrics",
          name: t("accessibilityChangedMetrics"),
          component: AccessibilityChangedMetrics,
        },
        ...attachmentSections,
      ],
      reports: reports.map((x) => ({
        code: x.code,
        name: x.name,
        items: x.items.map((report) => {
          function ReportWrapper(): JSX.Element {
            return <AccessibilityReport reportFilter={report.filter} />;
          }

          function ReportCountWrapper(): JSX.Element | null {
            return report.countMetric || report.count !== undefined ? (
              <ReportCount
                code={report.countMetric}
                count={report.count}
                offset={report.countMetricOffset}
                metrics={metrics}
              />
            ) : null;
          }

          return {
            code: report.code,
            name: report.name,
            component: ReportWrapper,
            adornment: ReportCountWrapper,
            filter: report.filter,
          };
        }),
      })),
    }),
    [attachmentSections, metrics, reports, t],
  );

  return {
    section,
    ...findActive(section, type),
  };
}

function findActive(
  section: AccessibilityDetailsSections,
  type?: string,
): { active: Section } {
  const children = [
    section,
    ...section.main,
    ...section.reports.flatMap((item) => item.items),
  ];

  return {
    active: children.find((child) => child.code === type) ?? children[0],
  };
}
