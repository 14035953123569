/* eslint-disable @typescript-eslint/no-explicit-any */
import { EnumType } from "json-to-graphql-query";
import { isEqual, merge } from "lodash";
import { getReportStatGUID } from "../../../../_common/report-helpers/reportStat";
import { DatasourceCode, ReportStat } from "../../../../graphql";
import { CustomDataAggregateMetricConfig } from "./types";
import { isAggregateWithUnit } from "./useReportsAggregateMetricData";

export function getCrawlUrlsReportsFragment(
  configs: CustomDataAggregateMetricConfig[],
):
  | {
      aggregates: Record<string, unknown>;
      datasource: Record<string, unknown>;
    }
  | undefined {
  const aggregates = configs.reduce<Record<string, unknown>>(
    (result, config) =>
      merge(
        result,
        config.crawlUrls
          ? {
              crawlUrlsAggregates: {
                [config.crawlUrls.aggregate]: {
                  [config.crawlUrls.metricCode]: true,
                },
              },
            }
          : {},
      ),
    {},
  );

  if (isEqual(aggregates, {})) return;

  return {
    aggregates,
    datasource: {
      crawlUrlsDatasource: {
        __aliasFor: "datasource",
        __args: {
          datasourceCode: new EnumType(DatasourceCode.CrawlUrls),
        },
        datasourceCode: true,
        metrics: {
          code: true,
          metadata: {
            unit: true,
          },
        },
      },
    },
  };
}

export function getCrawlUrlsValue(
  crawlUrlsReports: ReportStat[],
  reportId: string,
  config: CustomDataAggregateMetricConfig,
): number | null | undefined {
  if (!config.crawlUrls) return;

  const report = crawlUrlsReports.find(
    // FIXME: HACK: This allowed to match ReportStat and Report entities.
    // This feature should use ReportStat exclusively. For now, this works
    (x: ReportStat) => getReportStatGUID(x) === reportId,
  );
  if (!report) return;

  const aggregateNode = report?.crawlUrlsAggregates?.nodes?.[0];
  if (!aggregateNode) {
    return undefined;
  }

  const aggregateValue = aggregateNode[config.crawlUrls.aggregate] as Record<
    string,
    number | null | undefined
  >;
  if (!aggregateValue) {
    return undefined;
  }

  return aggregateValue[config.crawlUrls.metricCode];
}

export function getCrawlUrlsUnit(
  data: any,
  config: CustomDataAggregateMetricConfig,
): string | undefined {
  if (!config.crawlUrls || !isAggregateWithUnit(config.crawlUrls.aggregate)) {
    return;
  }

  return data?.crawlUrlsDatasource?.metrics?.find(
    (x: any) => x.code === config.crawlUrls?.metricCode,
  )?.metadata?.unit;
}
