import { ReportStat } from "../../../../graphql";

export const filterAggregatedReportStats = (
  reportStats: ReportStat[],
): {
  crawlUrls: ReportStat[];
  audits: ReportStat[];
  opportunities: ReportStat[];
} => {
  return reportStats.reduce<{
    crawlUrls: ReportStat[];
    audits: ReportStat[];
    opportunities: ReportStat[];
  }>(
    (result, reportStat) => {
      if (
        reportStat.basic &&
        reportStat.basic > 0 &&
        reportStat.reportTemplateTotalSign &&
        reportStat.reportTemplateTotalSign < 0
      ) {
        const isCrawlUrlsReport =
          (reportStat.crawlUrlsAggregates?.nodes ?? []).length > 0;
        const isAuditsReport =
          (reportStat.crawlSiteSpeedAuditsAggregates?.nodes ?? []).length > 0;
        const isOpportunitiesReport =
          (reportStat.crawlSiteSpeedAuditOpportunitiesAggregates?.nodes ?? [])
            .length > 0;

        return {
          crawlUrls: isCrawlUrlsReport
            ? [...result.crawlUrls, reportStat]
            : result.crawlUrls,
          audits: isAuditsReport
            ? [...result.audits, reportStat]
            : result.audits,
          opportunities: isOpportunitiesReport
            ? [...result.opportunities, reportStat]
            : result.opportunities,
        };
      }

      return result;
    },
    {
      crawlUrls: [],
      audits: [],
      opportunities: [],
    },
  );
};
