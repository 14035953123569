import { TFunction } from "i18next";
import { CrawlType } from "../../../graphql";
import { ChartConfigItem, VisualisationTypes } from "../types/ChartConfig";
import { generateSimpleMultiSeriesChartConfig } from "../utils/generateSimpleMultiSeriesChartConfig";

export function getGscTrafficFunnel(t: TFunction<"charts">): ChartConfigItem {
  return generateSimpleMultiSeriesChartConfig({
    visualisationType: VisualisationTypes.Pie,
    title: t("gscTrafficFunnel.title"),
    description: t("gscTrafficFunnel.description"),
    requiredSources: {
      sources: [CrawlType.GoogleSearchConsole],
      incompleteMessage: t("requiredSourcesMessage.gscSource"),
    },
    reportTemplateCodes: [
      "primary_pages_with_serp_clicks",
      "primary_pages_in_serp_without_clicks",
      "primary_pages_not_in_serp",
      "duplicate_pages_in_serp",
      "redirecting_pages_in_serp",
      "non_indexable_pages_in_serp",
      "error_pages_in_serp",
    ],
    pendoAttributePrefix: "google-search-console-serp-funnel-chart",
    testAttributePrefix: "google-search-console-serp-funnel-chart",
  })();
}
