import {
  Button,
  EditSolid,
  Select,
  Snackbar,
  TextField,
  Typography,
  useTranslation,
  XSolid,
} from "@lumar/shared";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  MenuItem,
} from "@material-ui/core";
import { useFormik } from "formik";

import { enqueueSnackbar } from "notistack";
import { useUpdateCustomReportTemplateMutation } from "../../../graphql";
import {
  mapTotalSignToInt,
  mapTotalWeightToFloat,
} from "../../_common/CustomReportHelpers";
import { UpdateCustomReportTemplateFormValues } from "../../_common/CustomReportTypes";
import { updateCustomReportTemplateSchema } from "../../_common/CustomReportValidation";

interface UpdateCustomReportDialogProps {
  isOpen: boolean;
  onClose: () => void;
  isLoading?: boolean;
  disabled?: boolean;
  defaultValues: UpdateCustomReportTemplateFormValues;
}

export const UpdateCustomReportDialog = (
  props: UpdateCustomReportDialogProps,
): JSX.Element => {
  const { t } = useTranslation("customReports");
  const classes = useStyles();

  const [updateCustomReportMutation] = useUpdateCustomReportTemplateMutation({
    onCompleted: () => {
      const title = t("customReportTemplateUpdated");
      enqueueSnackbar(<Snackbar title={title} variant="success" />);
      closeDialogAndReset();
    },
    onError: (error) => {
      enqueueSnackbar(<Snackbar title={error.message} variant="error" />);
    },
    refetchQueries: ["CustomReportTemplatesManager"],
  });

  const initialValues: UpdateCustomReportTemplateFormValues = {
    customReportTemplateId: props.defaultValues.customReportTemplateId,
    name: props.defaultValues.name,
    description: props.defaultValues.description ?? undefined,
    totalSign: props.defaultValues.totalSign,
    totalWeight: props.defaultValues.totalWeight,
  };

  const formik = useFormik<UpdateCustomReportTemplateFormValues>({
    enableReinitialize: true,
    initialValues,
    validationSchema: updateCustomReportTemplateSchema(t),
    onSubmit: async (values) => {
      await updateCustomReportMutation({
        variables: {
          input: {
            customReportTemplateId: values.customReportTemplateId,
            name: values.name,
            description: values.description,
            totalSign: mapTotalSignToInt(values.totalSign),
            totalWeight: mapTotalWeightToFloat(values.totalWeight),
          },
        },
      });
    },
  });

  const closeDialogAndReset = (): void => {
    props.onClose();
    formik.resetForm();
    formik.validateForm(initialValues);
  };

  return (
    <Dialog
      open={props.isOpen}
      onClose={() => closeDialogAndReset()}
      classes={{ paper: classes.dialog }}
    >
      <DialogTitle disableTypography className={classes.dialogTitle}>
        <Typography variant="h2">{t("updateCustomReport")}</Typography>
      </DialogTitle>

      <DialogContent className={classes.dialogContent}>
        <form
          id="createCustomReportTemplateForm"
          onSubmit={formik.handleSubmit}
        >
          <div className={classes.formLine}>
            <TextField
              label="Report name*"
              id="name"
              name="name"
              value={formik.values.name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
            />
          </div>
          <div className={classes.formLine}>
            <TextField
              label="Report description"
              multiline={true}
              minRows={4}
              maxRows={4}
              id="description"
              name="description"
              value={formik.values.description}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.description && Boolean(formik.errors.description)
              }
              helperText={
                formik.touched.description && formik.errors.description
              }
            />
          </div>
          <div className={classes.formTwoColumns}>
            <Select
              label="Impact"
              id="totalSign"
              name="totalSign"
              value={formik.values.totalSign}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            >
              <MenuItem value="negative">{t("templateSign.negative")}</MenuItem>
              <MenuItem value="neutral">{t("templateSign.neutral")}</MenuItem>
              <MenuItem value="positive">{t("templateSign.positive")}</MenuItem>
            </Select>
            <Select
              label="Priority"
              id="totalWeight"
              name="totalWeight"
              value={formik.values.totalWeight}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            >
              <MenuItem value="none"> {t("templateWeight.none")}</MenuItem>
              <MenuItem value="low">{t("templateWeight.low")}</MenuItem>
              <MenuItem value="medium">{t("templateWeight.medium")}</MenuItem>
              <MenuItem value="high">{t("templateWeight.high")}</MenuItem>
              <MenuItem value="critical">
                {t("templateWeight.critical")}
              </MenuItem>
            </Select>
          </div>
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          disabled={formik.isSubmitting}
          variant="outlined"
          onClick={() => closeDialogAndReset()}
          startIcon={<XSolid />}
        >
          {t("cancel")}
        </Button>
        <Button
          loading={formik.isSubmitting}
          disabled={!formik.isValid || formik.isSubmitting}
          type="submit"
          form="createCustomReportTemplateForm"
          variant="contained"
          color="primary"
          startIcon={<EditSolid />}
        >
          {t("updateCustomReport")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const useStyles = makeStyles((theme) => ({
  formLine: {
    marginBottom: theme.spacing(2),
  },
  formTwoColumns: {
    display: "flex",
    justifyContent: "space-between",
    "& > *": {
      width: "calc(50% - 4px)",
    },
  },
  dialog: {
    width: "100%",
  },
  dialogTitle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    padding: theme.spacing(2, 3),
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
  },
  dialogContent: {
    padding: theme.spacing(3),
  },
}));
