import { ApolloError, useSession } from "@lumar/shared";
import React, { ReactNode } from "react";
import {
  ModuleCode,
  useGetCrawlReportCategoryHealthScoreQuery,
} from "../../../../graphql";
import {
  CrawlReportCategoryListNode,
  useCrawlContextData,
} from "../../../CrawlContext";
import { useCrawlOverviewContextData } from "../../../CrawlOverviewContext";
import { isFunction } from "lodash";
import { Routes } from "../../../../_common/routing/routes";

interface ChartCategoryHealthScore {
  loading: boolean;
  error?: ApolloError;
  /**
   * Trend ordered ascendingly by `dateTime`.
   */
  trend: {
    value: number;
    dateTime: number;
    crawlId: number;
    categoryWeight: number;
  }[];
  healthScore: number | null;
  healthScoreChangePercentage: number | null;
  industry?: {
    code: string;
    name: string;
    healthScoreBenchmark: number | undefined;
  };
  category: CrawlReportCategoryListNode;
  moduleCode: ModuleCode;
  link?: string;
}

const ChartCategoryHealthScoreContext =
  React.createContext<null | ChartCategoryHealthScore>(null);

/**
 * Safe hook accessing category health score context.
 * @author Michal Pietraszko
 */
export function useChartCategoryHealthScore(): ChartCategoryHealthScore {
  const context = React.useContext(ChartCategoryHealthScoreContext);
  if (context) {
    return context;
  }
  throw new Error(
    "Called `useChartCategoryHealthScore` hook without `<ChartIndustryHealthScoreBenchmarkProvider>` being present in the tree.",
  );
}

/**
 * Provides specific or currently selected category health score data.
 * Note: Maybe this should be a part of CrawlOverviewContextProvider?
 * @author Michal Pietraszko
 */
export function ChartCategoryHealthScoreProvider(props: {
  children: ReactNode | ((context: ChartCategoryHealthScore) => ReactNode);
  reportCategoryCode?: string;
}): JSX.Element {
  const crawlData = useCrawlContextData();
  const crawlOverviewData = useCrawlOverviewContextData();
  const session = useSession();

  const category = props.reportCategoryCode
    ? (crawlData.crawlReportCategoriesList.find(
        (crawlReportCategory) =>
          crawlReportCategory.code === props.reportCategoryCode,
      ) ?? crawlOverviewData.selectedCategory)
    : crawlOverviewData.selectedCategory;

  const { data, loading, error } = useGetCrawlReportCategoryHealthScoreQuery({
    fetchPolicy: "cache-first",
    context: {
      includeInBatch: true,
    },
    variables: {
      crawlId: crawlData.crawl.rawID,
      segmentId: crawlData.selectedCrawlSegment?.segment.id,
      reportCategoryCode: category.code,
      industryCode: crawlData.crawlProject.industryCode,
      fetchBenchmark:
        session.hasAddon("industry-benchmarks") &&
        !!crawlData.crawlProject.industryCode,
    },
  });

  const industry = data?.getIndustryBenchmarks?.edges?.[0]?.node
    ? {
        code: data.getIndustryBenchmarks.edges[0].node.industryCode,
        name: data.getIndustryBenchmarks.edges[0].node.industryCode.replace(
          "_",
          " ",
        ),
        healthScoreBenchmark:
          data.getIndustryBenchmarks.edges[0].node.healthScore,
      }
    : undefined;

  const healthScore =
    data?.crawl?.healthScoreTrend.find(
      (dataPoint) => dataPoint.crawlId === Number(crawlData.crawl.rawID),
    )?.score ?? null;

  // eslint-disable-next-line fp/no-mutating-methods
  const trend = (data?.crawl?.healthScoreTrend ?? [])
    .filter(
      (
        dataPoint,
      ): dataPoint is {
        createdAt: string;
        crawlId: number;
        score: number;
        categoryWeight: number;
      } => {
        /**
         * `createdAt` is undefined for old crawls in dev and staging environments
         */
        return !!dataPoint.createdAt;
      },
    )
    .map((dataPoint) => {
      return {
        crawlId: dataPoint.crawlId,
        dateTime: new Date(dataPoint.createdAt).getTime(),
        value: dataPoint.score,
        categoryWeight: dataPoint.categoryWeight,
      };
    })
    // Sorting to ensure correct visualization because
    // the api doesn't guarantee the order.
    .sort((a, b) => a.dateTime - b.dateTime);

  const values = {
    loading,
    error,
    healthScore,
    trend,
    industry,
    category,
    healthScoreChangePercentage: getHealthScoreChangePercentage(trend),
    moduleCode: crawlData.moduleCode,
    link: Routes.CrawlOverview.getUrl({
      accountId: crawlData.crawlProject.account.rawID,
      projectId: crawlData.crawlProject.rawID,
      crawlId: crawlData.crawl.rawID,
      segmentId: crawlData.selectedCrawlSegment?.segment.id,
      type: "dashboard",
      category: props.reportCategoryCode,
    }),
  };

  return (
    <ChartCategoryHealthScoreContext.Provider value={values}>
      {isFunction(props.children) ? props.children(values) : props.children}
    </ChartCategoryHealthScoreContext.Provider>
  );
}

/**
 * Returns recent health score change as percentage.
 * @author Michal Pietraszko
 */
function getHealthScoreChangePercentage(
  healthScoreTrend: { value: number }[],
): number | null {
  if (healthScoreTrend.length <= 1) {
    return null;
  }

  // last element in the array
  const currentScore = healthScoreTrend[healthScoreTrend.length - 1].value;
  // penultimate element in the array
  const previousScore = healthScoreTrend[healthScoreTrend.length - 2].value;

  if (previousScore === 0) {
    if (currentScore === 0) {
      return 0;
    }
    return 1;
  }

  const changePercentage =
    Math.floor(currentScore * 100) / Math.floor(previousScore * 100) - 1;

  if (isFinite(changePercentage)) {
    return changePercentage;
  }

  return 0;
}
