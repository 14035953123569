import { ChartIconButton, Snackbar, ZoomOutMap, useBrand } from "@lumar/shared";
import { lighten, useTheme } from "@material-ui/core";
import GetAppIcon from "@material-ui/icons/GetApp";
import { escape } from "lodash";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useCrawlContextData } from "../../../crawl-overview/CrawlContext";
import { assert } from "../../assert";
import { useGenericParams } from "../../routing/useGenericParams";
import { useSearchParam } from "../../routing/useSearchParam";
import { useChartReportColorGetter } from "../chart-colors/useChartReportColors";
import {
  ChartDataContext,
  useChartDataContext,
} from "../components/chart-components/ChartDataContext";
import { ChartDropdownMenu } from "../components/chart-components/ChartDropdownMenu";
import { ChartPanelButton } from "../components/chart-components/ChartPanelButton";
import { ChartPanelContent } from "../components/chart-components/ChartPanelContent";
import { ChartPanelTitle } from "../components/chart-components/ChartPanelTitle";
import { ChartWrapper } from "../components/chart-components/ChartWrapper";
import { ChartPanelErrorMessage } from "../components/chart-messages/ChartPanelErrorMessage";
import { ChartPanelMessageWrapper } from "../components/chart-messages/ChartPanelMessageWrapper";
import { FullscreenDialog } from "../components/FullscreenDialog";
import { ChartRef } from "../components/HighchartsChart";
import { VisualisationTypes } from "../types/ChartConfig";
import { ExportType, ExportTypeMap } from "../types/ExportTypes";

import { BarChart } from "./BarChart";
import {
  ChartConfigItemMultiSeries,
  MultiSeriesVisualisationTypes,
} from "./ChartConfigItemMultiSeries";
import { PieChart } from "./PieChart";
import { Series, SeriesDataItem } from "./types";
import {
  ChartConfigReportStatArray,
  ChartConfigReportStatArrayElement,
} from "../types/ChartConfigItemBase";
import { getIconForChart } from "../../../crawl-overview/dashboard/data-visualization/charts/getIconForChart";

const VisualisationTypeToChartMap = {
  [VisualisationTypes.Pie]: PieChart,
  [VisualisationTypes.Bar]: BarChart,
};

type Props = ChartConfigItemMultiSeries;

export function MultiSeriesChart(props: Props): JSX.Element {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const {
    reportStats,
    segmentName,
    isChartUnavailable,
    totalUrls,
    getReportUnit,
  } = useChartDataContext();

  const { accountId, projectId, crawlId } = useGenericParams();
  const { selectedCrawlSegment } = useCrawlContextData();
  const category = useSearchParam("category");
  const { t } = useTranslation(["common", "charts"]);
  const chartRef = React.createRef<ChartRef>();
  const { enqueueSnackbar } = useSnackbar();

  const [visualisationType, setVisualisationType] = React.useState(
    props.visualisationType,
  );

  function handleExportOptionChange(exportType: ExportType): void {
    if (exportType === "CSV") {
      chartRef.current?.chart.downloadCSV();
    } else {
      chartRef.current?.chart.exportChartLocal(
        { type: ExportTypeMap.get(exportType) },
        {},
      );
    }
    enqueueSnackbar(
      <Snackbar
        variant="success"
        title={t("charts:chartDownloadMessage", { exportType })}
      />,
    );
  }

  const series = useSeries(
    reportStats as ChartConfigReportStatArray,
    props,
    segmentName,
    getReportUnit,
  );
  const Component = VisualisationTypeToChartMap[visualisationType];
  const hasNoData = series.every((serie) => !serie.data.length);

  assert(accountId);
  assert(projectId);
  assert(crawlId);

  const title = props.title(reportStats as ChartConfigReportStatArray);

  const totalCount = reportStats.length;

  const visualisationOptions = props.additionalVisualisationTypes
    ? [props.visualisationType, ...props.additionalVisualisationTypes]
    : undefined;

  const shouldDisableChartOptions = isChartUnavailable || hasNoData;

  const moreLink = props.moreLink?.href({
    accountId,
    projectId,
    crawlId,
    segmentId: selectedCrawlSegment?.segment?.id,
    category: category,
  });
  const moreLinkText = props.moreLink?.title(
    totalCount,
    reportStats as ChartConfigReportStatArray,
    totalUrls ?? 0,
  );

  return (
    <>
      <ChartWrapper
        data-testid={props.testAttributePrefix}
        data-pendo={props.pendoAttributePrefix}
      >
        <ChartPanelTitle
          title={title}
          icon={getIconForChart(props.icon)}
          description={
            props.description
              ? props.description(
                  { accountId, projectId, crawlId },
                  reportStats as ChartConfigReportStatArray,
                )
              : undefined
          }
          descriptionTitle={props.descriptionTitle?.(
            reportStats as ChartConfigReportStatArray,
          )}
          segmentName={segmentName}
        >
          <ChartPanelButton link={moreLink} label={moreLinkText} />
          {!props.disableFullscreen && (
            <ChartIconButton
              tooltip={t("charts:openFullscreen")}
              onClick={() => setModalIsOpen(true)}
              icon={ZoomOutMap}
              data-pendo={
                props.pendoAttributePrefix
                  ? `${props.pendoAttributePrefix}-open-fullscreen`
                  : undefined
              }
            />
          )}
          <ChartDropdownMenu<MultiSeriesVisualisationTypes>
            title={t("charts:chooseChartType")}
            selectedOption={visualisationType}
            options={visualisationOptions}
            handleOptionChange={setVisualisationType}
            isDisabled={shouldDisableChartOptions}
            pendoAttributePrefix={
              props.pendoAttributePrefix
                ? `${props.pendoAttributePrefix}-chart-type`
                : undefined
            }
          />
          <ChartDropdownMenu<ExportType>
            title={t("charts:exportOptions")}
            options={["PDF", "PNG", "CSV"]}
            handleOptionChange={handleExportOptionChange}
            icon={GetAppIcon}
            isDisabled={shouldDisableChartOptions}
            pendoAttributePrefix={
              props.pendoAttributePrefix
                ? `${props.pendoAttributePrefix}-export`
                : undefined
            }
          />
        </ChartPanelTitle>
        <ChartPanelContent requiredSources={props.requiredSources}>
          {hasNoData ? (
            <ChartPanelMessageWrapper>
              <ChartPanelErrorMessage>
                {Boolean(selectedCrawlSegment)
                  ? t("charts:noDataInSegment", { segmentName })
                  : t("charts:noData")}
              </ChartPanelErrorMessage>
            </ChartPanelMessageWrapper>
          ) : (
            <Component
              {...props}
              title={title}
              series={series}
              ref={chartRef}
            />
          )}
        </ChartPanelContent>
      </ChartWrapper>
      {!props.disableFullscreen && (
        <FullscreenDialog
          title={title}
          open={modalIsOpen}
          onClose={() => setModalIsOpen(false)}
          content={
            <ChartPanelContent requiredSources={props.requiredSources}>
              {hasNoData ? (
                <ChartPanelMessageWrapper>
                  <ChartPanelErrorMessage>
                    {Boolean(selectedCrawlSegment)
                      ? t("charts:noDataInSegment", { segmentName })
                      : t("charts:noData")}
                  </ChartPanelErrorMessage>
                </ChartPanelMessageWrapper>
              ) : (
                <Component {...props} title={title} series={series} />
              )}
            </ChartPanelContent>
          }
        />
      )}
    </>
  );
}

function useSeries(
  reports: ChartConfigReportStatArray,
  props: Props,
  segmentName: string | undefined,
  getReportUnit: ChartDataContext["getReportUnit"],
): Series {
  const brand = useBrand();
  const { t } = useTranslation("charts");
  const theme = useTheme();
  const getReportColor = useChartReportColorGetter();

  const hasTotal = reports.find(
    (data) =>
      escape(props.serieName(data as ChartConfigReportStatArrayElement)) ===
      t("non200Pages.total"),
  );

  function getSerieColor(code: string, index: number): string | undefined {
    if (brand.featureAvailability.chartReportSerieColor) {
      if (props.visualisationColors) {
        return props.visualisationColors[index];
      }

      return getReportColor(code);
    }

    if (hasTotal) {
      return theme.palette.sequentialChart?.[index];
    }

    if (reports.length === 2) {
      return theme.palette.dualChart?.[index];
    }

    return theme.palette.chart[index];
  }

  // Note: Not mutating arguments so it should be fine.
  // eslint-disable-next-line fp/no-let
  let seriesData: SeriesDataItem[] = reports.map((report, index) => {
    const name = escape(
      props.serieName(report as ChartConfigReportStatArrayElement),
    );
    const reportColor = getSerieColor(report.reportTemplateCode, index);

    // FIXME: "Other" is a hardcoded name used when comparing specific data point to the rest of data points.
    const color =
      name === "Other"
        ? reportColor
          ? lighten(reportColor, 0.5)
          : theme.palette.grey[300]
        : reportColor;

    return {
      name,
      color,
      y: props.count(report as ChartConfigReportStatArrayElement, reports),
      // Prioritising `reportTemplate.code` because it won't include the aggregate in it.
      reportTemplateCode:
        report.reportTemplate?.code ?? report.reportTemplateCode,
      reportNameInTooltip: props.nameInTooltip
        ? props.nameInTooltip(report as ChartConfigReportStatArrayElement)
        : report.reportTemplateName,
      unit: getReportUnit(report),
      segmentId: report?.segment?.id,
      segmentName: segmentName,
    };
  });

  // FIXME: "non200Pages.total" ("Total" in english locale) is a hardcoded name used when a specific report is used as total aggregation of other reports.
  const totalSeriesData = seriesData.find(
    (data) => data.name === t("non200Pages.total"),
  );

  // Note: If "Total" exists, then make sure it appears as first.
  if (totalSeriesData) {
    // eslint-disable-next-line fp/no-mutation
    seriesData = seriesData.filter((data) => data !== totalSeriesData);
    // eslint-disable-next-line fp/no-mutating-methods
    seriesData.unshift(totalSeriesData);
  }

  const series: Series = [
    {
      name: "URLs",
      data: seriesData,
    },
  ];

  return series;
}
