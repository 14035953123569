import React from "react";
import {
  Typography,
  useTranslation,
  PriorityHighestIcon,
  PriorityHighIcon,
  PriorityMediumIcon,
  PriorityLowIcon,
  PriorityLowestIcon,
  Done,
} from "@lumar/shared";
import {
  makeStyles,
  SvgIcon,
  Tooltip as MuiTooltip,
  TooltipProps,
} from "@material-ui/core";
import { LegacyTaskPriority, LegacyTaskStatus } from "../graphql";
import { TFunction } from "i18next";

export function TaskBanner({
  description,
  priority,
  status,
}: {
  description: string;
  priority: LegacyTaskPriority;
  status?: LegacyTaskStatus | null;
}): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation(["taskReport", "taskManager"]);

  const PriorityIcon = getIcon(priority);

  return (
    <div className={classes.banner}>
      <div className={classes.container}>
        <div className={classes.descriptionAndTitleContainer}>
          <Typography variant="captionSemiBold" className={classes.title}>
            <Done className={classes.titleIcon} /> {t("pageTitleDefault")}
          </Typography>

          <Tooltip title={description} classes={{ tooltip: classes.tooltip }}>
            <Typography className={classes.description}>
              <Typography
                className={classes.descriptionLabel}
                variant="subtitle3SemiBold"
              >
                {t("description")}:
              </Typography>
              {description || "-"}
            </Typography>
          </Tooltip>
        </div>
        <div className={classes.priorityContainer}>
          <div className={classes.priority}>
            <Typography variant="subtitle3SemiBold">{t("priority")}</Typography>
            <Typography variant="caption">
              {PriorityIcon && (
                <PriorityIcon
                  className={classes.priorityIcon}
                  viewBox="2 2 16 16"
                />
              )}
              {getPriorityName(t, priority)}
            </Typography>
          </div>
        </div>
        <div className={classes.statusContainer}>
          <div className={classes.status}>
            <Typography variant="subtitle3SemiBold">{t("status")}</Typography>
            <Typography variant="caption">
              {getStatusName(t, status || LegacyTaskStatus.Backlog)}
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
}

function getIcon(priority: LegacyTaskPriority): typeof SvgIcon | undefined {
  switch (priority) {
    case LegacyTaskPriority.Critical:
      return PriorityHighestIcon;
    case LegacyTaskPriority.High:
      return PriorityHighIcon;
    case LegacyTaskPriority.Medium:
      return PriorityMediumIcon;
    case LegacyTaskPriority.Low:
      return PriorityLowIcon;
    case LegacyTaskPriority.Note:
      return PriorityLowestIcon;
  }
}

function getStatusName(
  t: TFunction<["taskManager", "taskManager"]>,
  status?: LegacyTaskStatus,
): string | undefined {
  switch (status) {
    case LegacyTaskStatus.Done:
      return t("taskManager:status.done");
    case LegacyTaskStatus.Testing:
      return t("taskManager:status.testing");
    case LegacyTaskStatus.InProgress:
      return t("taskManager:status.inProgress");
    case LegacyTaskStatus.ToDo:
      return t("taskManager:status.toDo");
    case LegacyTaskStatus.Backlog:
      return t("taskManager:status.backlog");
    default:
      return status;
  }
}

function getPriorityName(
  t: TFunction<["taskManager", "taskManager"]>,
  priority: LegacyTaskPriority,
): string {
  switch (priority) {
    case LegacyTaskPriority.Note:
      return t("taskManager:severity.note");
    case LegacyTaskPriority.Low:
      return t("taskManager:severity.low");
    case LegacyTaskPriority.Medium:
      return t("taskManager:severity.medium");
    case LegacyTaskPriority.High:
      return t("taskManager:severity.high");
    case LegacyTaskPriority.Critical:
    default:
      return t("taskManager:severity.critical");
  }
}

function Tooltip({
  children,
  ...tooltipProps
}: {
  children: React.ReactElement;
} & TooltipProps): JSX.Element {
  const ref = React.useRef<HTMLElement | null>(null);
  const [truncated, setTruncated] = React.useState(false);

  React.useLayoutEffect(() => {
    if (!ref.current) return;

    try {
      const observer = new ResizeObserver(() => {
        const truncated =
          (ref.current?.clientHeight ?? 0) < (ref.current?.scrollHeight ?? 0);
        setTimeout(() => setTruncated(truncated), 0);
      });
      observer.observe(ref.current);

      return () => observer.disconnect();
    } catch {
      setTruncated(true);
    }
  }, [truncated]);

  const child = React.cloneElement(children, { ...children.props, ref: ref });

  if (!truncated) return <>{child}</>;

  return <MuiTooltip {...tooltipProps}>{child}</MuiTooltip>;
}

const useStyles = makeStyles((theme) => ({
  banner: {
    backgroundColor: theme.palette.taskReportBannerColor,
    color: "white",
    margin: theme.spacing(0, -2.5, 0, -2.875),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(2.5, 0, 2.5, 0),
  },
  titleIcon: {
    fontSize: theme.typography.pxToRem(15),
    margin: theme.spacing(0, 0.5, 0, 0),
  },
  title: {
    display: "flex",
    width: "100%",
    textTransform: "uppercase",
    padding: theme.spacing(0, 1.75, 1.25, 0),
  },
  container: {
    lineHeight: theme.typography.pxToRem(16),
    display: "flex",
    width: "100%",
  },
  descriptionAndTitleContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: 2,
    flexGrow: 1,
    padding: theme.spacing(0, 1.75, 0, 3),
    overflow: "hidden",
  },
  descriptionLabel: {
    margin: theme.spacing(0, 0.5, 0, 0),
  },
  description: {
    maxWidth: "75%",
    lineHeight: theme.typography.pxToRem(18),
    textOverflow: "ellipsis",
    overflow: "hidden",
    display: "-webkit-box",
    "-webkit-line-clamp": 2,
    lineClamp: 2,
    "-webkit-box-orient": "vertical",
  },
  priorityContainer: {
    display: "flex",
    flexDirection: "column",
    "justify-content": "center",
  },
  statusContainer: {
    display: "flex",
    flexDirection: "column",
    "justify-content": "center",
  },
  priority: {
    display: "flex",
    flexDirection: "column",
    whiteSpace: "nowrap",
    gap: 2,
    "justify-content": "center",
    borderLeft: "1px solid rgba(255, 255, 255, 0.3)",
    padding: theme.spacing(0, 1.75),
  },
  status: {
    display: "flex",
    flexDirection: "column",
    whiteSpace: "nowrap",
    gap: 2,
    "justify-content": "center",
    borderLeft: "1px solid rgba(255, 255, 255, 0.3)",
    padding: theme.spacing(0, 3, 0, 1.75),
  },
  priorityIcon: {
    fontSize: theme.typography.pxToRem(18),
    margin: theme.spacing(-0.25, 0.5, -0.5, 0),
  },
  tooltip: {
    maxWidth: 600,
  },
}));
