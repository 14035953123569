/* eslint-disable react/display-name */
import { TFunction } from "i18next";

import { ChartConfigItem, VisualisationTypes } from "../types/ChartConfig";
import { ModuleCode } from "@lumar/shared/dist/graphql";

export function getExtractReportsChart(
  t: TFunction<"charts">,
): ChartConfigItem {
  return {
    inputSource: "CrawlContext",
    visualisationFamily: "MultiSeries",
    visualisationType: VisualisationTypes.Pie,
    additionalVisualisationTypes: [VisualisationTypes.Bar],
    supportedModules: [
      ModuleCode.Accessibility,
      ModuleCode.Seo,
      ModuleCode.Basic,
    ],
    title: () => t("extractReports.title"),
    description: () => t("extractReports.description"),
    reportStatFilter: (report) => {
      return ["extract_report", "extract_complement_report"].includes(
        report.reportTemplateCode,
      );
    },

    showPercentageInSeriesTooltip: true,

    serieName: (report) => {
      return report.reportTemplateName;
    },
    count: (report) => {
      return report.basic ?? 0;
    },
    testAttributePrefix: "extract-reports-chart",
  };
}
