import { ChartBar, Code, FileText, useTranslation } from "@lumar/shared";
import {
  Metrics,
  MetricsValuePresenterProps,
  ResourceDetailData,
} from "../../data/types";
import { ResourceDetailBase } from "../../ResourceDetail";
import {
  Indicator,
  ResourceDetailSummary,
  ResourceDetailSummaryConfig,
} from "../../ResourceDetailSummary";
import { useBasicDetailsSections } from "./useBasicDetailsSections";
import {
  CrawlUrlPageTypePresenter,
  SourcePresenter,
  StatusCodeSimplePresenter,
} from "../../metrics-value-presenter";

export function BasicDetails({
  data,
}: {
  data: ResourceDetailData;
}): JSX.Element {
  const { t } = useTranslation("resourceDetail");

  const { active } = useBasicDetailsSections(data.metrics);

  const config = useSummaryConfig(data.metrics);

  return (
    <ResourceDetailBase
      title={t("defaultTitle")}
      navigationReportTemplate={data.navigationReportTemplate}
    >
      <ResourceDetailSummary metrics={data.metrics} config={config} />
      <active.component data={data} />
    </ResourceDetailBase>
  );
}

function useSummaryConfig(metrics: Metrics): ResourceDetailSummaryConfig {
  const { t } = useTranslation("resourceDetail");

  const getMetricProps = (code: string): MetricsValuePresenterProps => ({
    code,
    metrics,
    value: metrics[code]?.value,
  });

  return {
    summary: [
      { code: "url" },
      { code: "foundAtUrl" },
      { code: "foundAtSitemap" },
      {
        code: "foundIn",
        name: t("metricFoundIn"),
        value: <SourcePresenter {...getMetricProps("foundIn")} />,
      },
    ],
    cardDetails: [
      {
        code: "issueType",
        name: t("pageType"),
        icon: FileText,
        value: <CrawlUrlPageTypePresenter {...getMetricProps("issueType")} />,
      },
      {
        code: "httpStatusCode",
        icon: Code,
        value: (
          <StatusCodeSimplePresenter {...getMetricProps("httpStatusCode")} />
        ),
        indicator: getHttpStatusCodeIndicator(
          String(metrics["httpStatusCode"]?.value),
        ),
      },
      {
        code: "level",
        icon: ChartBar,
      },
    ],
  };
}

function getHttpStatusCodeIndicator(status: string): Indicator {
  if (status.startsWith("2")) return "success";
  if (status.startsWith("3")) return "warning";
  return "error";
}
