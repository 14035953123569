/* eslint-disable react/display-name */
import { Theme } from "@material-ui/core";
import { TFunction } from "i18next";

import { ChartConfigItem, VisualisationTypes } from "../types/ChartConfig";
import {
  ModuleCode,
  ReportStatTrendItem,
  GetSiteSpeedMainthreadTrendDataDocument,
} from "../../../graphql";

import { Routes } from "../../routing/routes";
import { getRawCrawlId, DateFormatter } from "@lumar/shared";

import { RunNewCrawlMessage } from "../components/chart-messages/RunNewCrawlMessage";

export function getSiteSpeedMainthreadTrend(
  t: TFunction<"charts">,
  _: Theme,
  formatter: DateFormatter,
): ChartConfigItem {
  return {
    inputSource: "GqlQuery",
    visualisationFamily: "MultiDimensionalSeries",
    visualisationType: VisualisationTypes.Line,
    supportedModules: [ModuleCode.SiteSpeed],
    additionalVisualisationTypes: [
      VisualisationTypes.Area,
      VisualisationTypes.StackedArea,
      VisualisationTypes.FullStackedArea,
    ],
    icon: "lighthouse",
    title: () => t("siteSpeedMainthreadTrend.title"),
    description: () => t("siteSpeedMainthreadTrend.description"),
    gqlDocument: GetSiteSpeedMainthreadTrendDataDocument,
    gqlVariables: (crawlContext) => {
      return {
        inputs: [
          "mainthread_script_evaluation_items:wasted_secs_avg",
          "mainthread_other_items:wasted_secs_avg",
          "mainthread_style_layout_items:wasted_secs_avg",
          "mainthread_script_parsing_items:wasted_secs_avg",
          "mainthread_parse_html_css_items:wasted_secs_avg",
          "mainthread_rendering_items:wasted_secs_avg",
          "mainthread_garbage_collection_items:wasted_secs_avg",
        ].map((reportTemplateCode) => {
          return {
            crawlId: crawlContext.crawl.rawID,
            segmentId: crawlContext.selectedCrawlSegment?.segment?.rawID,
            reportTemplateCode: reportTemplateCode,
          };
        }),
      };
    },
    reportTemplateCodesOrder: [
      "mainthread_script_evaluation_items:wasted_secs_avg",
      "mainthread_other_items:wasted_secs_avg",
      "mainthread_style_layout_items:wasted_secs_avg",
      "mainthread_script_parsing_items:wasted_secs_avg",
      "mainthread_parse_html_css_items:wasted_secs_avg",
      "mainthread_rendering_items:wasted_secs_avg",
      "mainthread_garbage_collection_items:wasted_secs_avg",
    ],
    series: (reports, { accountId, projectId, segmentId }) =>
      reports.map((report) => {
        return {
          name: report.reportTemplateName,
          reportTemplateCode: report.reportTemplateCode,
          data: (report.trend ?? [])
            .filter(
              (
                trend,
              ): trend is ReportStatTrendItem & {
                createdAt: string;
              } => !!trend?.createdAt,
            )
            .map((trend) => {
              const createdAt = new Date(trend.createdAt);
              return {
                x: createdAt,
                y: trend.basic ?? 0,
                url: Routes.Report.getUrl({
                  accountId,
                  projectId,
                  crawlId: getRawCrawlId(String(trend.crawlId)),
                  segmentId,
                  reportTemplateCode:
                    report.reportTemplate?.code ?? report.reportTemplateCode,
                  reportTypeCode: "basic",
                }),
                additionalTooltipLines: [
                  formatter(createdAt, {
                    dateStyle: "medium",
                    timeStyle: "short",
                  }),
                ],
              };
            }),
        };
      }),

    incompleteSeriesTemplate: (
      <RunNewCrawlMessage message={t("runNewCrawlForTrendMessage")} />
    ),
  };
}
