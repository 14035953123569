import { ResourceDetailData } from "../../../data/types";
import { ChangedMetricsTable } from "../../../metrics-table/ChangedMetricsTable";
import { useExportName } from "../../useExportName";
import { shouldShowInChangedMetric } from "./metricFilters";

export function BasicChangedMetrics({
  data,
}: {
  data: ResourceDetailData;
}): JSX.Element {
  const getExportName = useExportName({
    crawlCreatedAt: data.crawl.createdAt,
    suffix: "changed_metrics",
  });

  return (
    <ChangedMetricsTable
      metrics={data.metrics}
      sourceTemplate={data.sourceTemplate}
      currentCrawlDate={data.crawl.createdAt}
      exportFileName={getExportName}
      filter={(metric) =>
        shouldShowInChangedMetric(metric.code) &&
        !metric.code.endsWith("ChangedFrom")
      }
    />
  );
}
