import React from "react";
import { useTranslation, Grid } from "@lumar/shared";
import { useSearchParam } from "../../../_common/routing/useSearchParam";
import { Metrics, ResourceDetailData } from "../../data/types";
import { BasicAllMetrics } from "./sections/BasicAllMetrics";
import { ResponseHeader } from "../../metrics-groups/ResponseHeader";
import { BasicChangedMetrics } from "./sections/BasicChangedMetrics";
import { SvgIcon } from "@material-ui/core";
import { insertIf } from "../../../_common/insertIf";
import { ReportRowsFilter } from "../../../_common/connection-filtering/types";
import { useAttachmentSections } from "../../attachments/useAttachmentSections";

type Section = {
  code: string;
  name: string;
  component: React.ElementType<{ data: ResourceDetailData }>;
};

export type ReportConfig = {
  code: string;
  name?: string;
  items: {
    code: string;
    name: string;
    count?: number;
    countMetric?: string;
    countMetricOffset?: number;
    filter: ReportFilter;
  }[];
};

export type ReportFilter = {
  reportTemplateCode: string;
  filter: ReportRowsFilter;
};

export type BasicDetailsSections = {
  code: string;
  icon: typeof SvgIcon;
  name: string;
  component: React.ElementType<{ data: ResourceDetailData }>;
  main: Section[];
};

type Result = {
  section: BasicDetailsSections;
  active: Section;
};

export function useBasicDetailsSections(metrics: Metrics): Result {
  const { t } = useTranslation("resourceDetail");
  const type = useSearchParam("type");

  const attachmentSections = useAttachmentSections(metrics);

  const rawHeader = metrics["rawHeader"]?.value;
  const hasRawHeader =
    typeof rawHeader === "object" && Object.keys(rawHeader).length > 0;

  const section = React.useMemo<BasicDetailsSections>(
    () => ({
      code: "basic",
      name: t("basicAllMetrics"),
      icon: Grid,
      component: BasicAllMetrics,
      main: [
        {
          code: "basicChangedMetrics",
          name: t("basicChangedMetrics"),
          component: BasicChangedMetrics,
        },
        ...insertIf(hasRawHeader, {
          code: "responseHeader",
          name: t("responseHeader"),
          component: ResponseHeader,
        }),
        ...attachmentSections,
      ],
    }),
    [t, hasRawHeader, attachmentSections],
  );

  return {
    section,
    ...findActive(section, type),
  };
}

function findActive(
  section: BasicDetailsSections,
  type?: string,
): { active: Section } {
  const children = [section, ...section.main];

  return {
    active: children.find((child) => child.code === type) ?? children[0],
  };
}
