/* eslint-disable react/display-name */
import { TFunction } from "i18next";
import { Theme } from "@material-ui/core";
import { ChartConfigItem, VisualisationTypes } from "../types/ChartConfig";
import { ModuleCode } from "../../../graphql";

const allCustomExtractionReportTemplateCodes = Array.from(
  { length: 30 },
  (_, i) => `custom_extraction_${i + 1}`,
);

export function getCustomExtractionChart(
  t: TFunction<"charts">,
  theme: Theme,
): ChartConfigItem {
  return {
    inputSource: "CrawlContext",
    visualisationFamily: "MultiSeries",
    visualisationType: VisualisationTypes.Bar,
    visualisationColors: [theme.palette.green[300]],
    supportedModules: [
      ModuleCode.Accessibility,
      ModuleCode.Seo,
      ModuleCode.Basic,
    ],
    title: () => t("customExtraction.title"),
    description: () => t("customExtraction.description"),
    reportStatsLimit: 9,
    reportStatFilter: (report) => {
      return (
        Number(report.basic) > 0 &&
        allCustomExtractionReportTemplateCodes.includes(
          report.reportTemplateCode,
        )
      );
    },
    reportStatsOrderBy: {
      field: ["basic", "reportTemplateCode"],
      direction: ["desc", "asc"],
    },
    serieName: (report) => {
      return report.reportTemplateName;
    },
    count: (report) => {
      return report.basic ?? 0;
    },
    nameInTooltip: (report) => {
      return report.reportTemplateName;
    },
    testAttributePrefix: "custom-extraction-chart",
  };
}
