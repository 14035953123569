/* eslint-disable react/display-name */
import { Theme } from "@material-ui/core";
import { TFunction } from "i18next";

import { ChartConfigItem, VisualisationTypes } from "../types/ChartConfig";
import { ModuleCode, ReportStatTrendItem } from "../../../graphql";

import { Routes } from "../../routing/routes";
import { getRawCrawlId, DateFormatter } from "@lumar/shared";

import { RunNewCrawlMessage } from "../components/chart-messages/RunNewCrawlMessage";

export function getSiteSpeedTBTTrend(
  t: TFunction<"charts">,
  _: Theme,
  formatter: DateFormatter,
): ChartConfigItem {
  return {
    inputSource: "CrawlContext",
    visualisationFamily: "MultiDimensionalSeries",
    visualisationType: VisualisationTypes.FullStackedArea,
    supportedModules: [ModuleCode.SiteSpeed],

    additionalVisualisationTypes: [
      VisualisationTypes.Area,
      VisualisationTypes.StackedArea,
      VisualisationTypes.Line,
    ],
    icon: "lighthouse",
    title: () => t("siteSpeedTBTTrend.title"),
    description: () => t("siteSpeedTBTTrend.description"),
    reportTemplateCodesOrder: [
      "total_blocking_time_slow",
      "total_blocking_time_moderate",
      "total_blocking_time_fast",
    ],
    reportStatFilter: (report) => {
      return [
        "total_blocking_time_slow",
        "total_blocking_time_moderate",
        "total_blocking_time_fast",
      ].includes(report.reportTemplateCode);
    },

    series: (reports, { accountId, projectId, segmentId }) =>
      reports.map((report) => {
        return {
          name: report.reportTemplateName,
          reportTemplateCode: report.reportTemplateCode,
          data: report.trend
            .filter(
              (
                trend,
              ): trend is ReportStatTrendItem & {
                createdAt: string;
              } => !!trend?.createdAt,
            )
            .map((trend) => {
              const createdAt = new Date(trend.createdAt);
              return {
                x: createdAt,
                y: trend.basic ?? 0,
                url: Routes.Report.getUrl({
                  accountId,
                  projectId,
                  crawlId: getRawCrawlId(String(trend.crawlId)),
                  segmentId,
                  reportTemplateCode: report.reportTemplateCode,
                  reportTypeCode: "basic",
                }),
                additionalTooltipLines: [
                  formatter(createdAt, {
                    dateStyle: "medium",
                    timeStyle: "short",
                  }),
                ],
              };
            }),
        };
      }),

    incompleteSeriesTemplate: (
      <RunNewCrawlMessage message={t("runNewCrawlForTrendMessage")} />
    ),
  };
}
