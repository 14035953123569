/* eslint-disable react/display-name */
/* eslint-disable fp/no-this */
import { Theme } from "@material-ui/core";
import { TFunction } from "i18next";

import { ChartConfigItem, VisualisationTypes } from "../types/ChartConfig";
import {
  ConnectionPredicate,
  GetStatusCodeChartDataDocument,
} from "../../../graphql";
import { Routes } from "../../routing/routes";
import { createConnectionFilter } from "../../connection-filtering/createConnectionFilter";

export function getNon200PagesbyStatusCode(
  t: TFunction<"charts">,
  theme: Theme,
): ChartConfigItem {
  return {
    inputSource: "GqlQuery",
    visualisationFamily: "MultiDimensionalSeries",
    visualisationType: VisualisationTypes.StackedColumn,
    visualisationColors: [theme.palette.red[500]],
    additionalVisualisationTypes: [
      VisualisationTypes.Line,
      VisualisationTypes.Area,
      VisualisationTypes.StackedArea,
      VisualisationTypes.FullStackedColumn,
      VisualisationTypes.FullStackedArea,
    ],
    title: () => t("non200PagesbyStatusCode.title"),
    description: () => t("non200PagesbyStatusCode.description"),
    gqlDocument: GetStatusCodeChartDataDocument,
    gqlVariables: (crawlContext) => {
      return {
        crawlId: crawlContext.crawl.rawID,
        segmentId: crawlContext.selectedCrawlSegment?.segment.rawID,
        reportTemplateCode: "all_pages",
        httpStatusCodeFilter: { gt: 299 },
      };
    },

    xAxisLabel: "HTTP Status Code",
    seriesCategories: (series) => {
      const categories = series[0]?.data.map((data) => `${data.x}`) || [];
      return categories;
    },
    series: (reports, linkData) => {
      return reports.map((report) => ({
        name: report.reportTemplateName,
        reportTemplateCode: report.reportTemplateCode,

        data: (report.crawlUrlsAggregates?.nodes ?? []).map(
          (crawlUrlsAggregate) => {
            const depthHttpStatusCode = crawlUrlsAggregate?.httpStatusCode ?? 0;
            const urlCount = crawlUrlsAggregate?.count?.url ?? 0;

            return {
              x: depthHttpStatusCode,
              y: urlCount,
              url: Routes.Report.getUrl({
                ...linkData,
                reportTemplateCode: report.reportTemplateCode,
                reportTypeCode: "basic",
                filter: createConnectionFilter({
                  or: [
                    {
                      and: [
                        {
                          metricCode: "httpStatusCode",
                          predicateKey: ConnectionPredicate.Eq,
                          predicateValue: depthHttpStatusCode,
                        },
                      ],
                    },
                  ],
                }),
              }),
              additionalTooltipLines: [
                t("non200PagesbyStatusCode.depthHttpStatusCode", {
                  depthHttpStatusCode,
                }),
              ],
            };
          },
        ),
      }));
    },
  };
}
