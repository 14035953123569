import { TFunction } from "i18next";
import { array, object, Schema, string } from "yup";
import {
  TOTAL_SIGN_OPTIONS,
  TOTAL_WEIGHT_OPTIONS,
} from "./CustomReportConstants";
import {
  CreateCustomReportTemplateFormValues,
  UpdateCustomReportTemplateFormValues,
} from "./CustomReportTypes";

export const createCustomReportTemplateSchema = (
  t: TFunction<"customReports">,
): Schema<CreateCustomReportTemplateFormValues> => {
  return object({
    projectId: string().required(),
    reportTemplateCode: string().required(),
    name: string()
      .required(t("validationErrors.requiredName"))
      .max(255, t("validationErrors.maxLengthName")),
    description: string(),
    totalSign: string().oneOf(TOTAL_SIGN_OPTIONS).required(),
    totalWeight: string().oneOf(TOTAL_WEIGHT_OPTIONS).required(),
    filter: object()
      .test({
        name: "nonEmptyObject",
        test: (value) => Object.keys(value).length > 0,
      })
      .required(),
    metricsGroupings: array()
      .of(object({ metrics: array().of(string().required()).required() }))
      .required(),
    orderBy: array().of(
      object({ field: string().required(), direction: string().required() }),
    ),
  });
};

export const updateCustomReportTemplateSchema = (
  t: TFunction<"customReports">,
): Schema<UpdateCustomReportTemplateFormValues> => {
  return object({
    customReportTemplateId: string().required(),
    name: string()
      .required(t("validationErrors.requiredName"))
      .max(255, t("validationErrors.maxLengthName")),
    description: string(),
    totalSign: string().oneOf(TOTAL_SIGN_OPTIONS).required(),
    totalWeight: string().oneOf(TOTAL_WEIGHT_OPTIONS).required(),
  });
};
